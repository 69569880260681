<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M2,14 L8,14 L8,20 L2,20 M16,8 L10,8 L10,10 L16,10 M2,10 L8,10 L8,4 L2,4 M10,4 L10,6 L22,6 L22,4 M10,20 L16,20 L16,18 L10,18 M10,16 L22,16 L22,14 L10,14"
    />
  </svg>
</template>
<script>
import { ALL_PROVIDER_FILTER_ICON } from '@/constants'

export default {
  name: ALL_PROVIDER_FILTER_ICON,
}
</script>
